import { Steps } from 'antd';

import { Description, DialogTitle, Field, Label } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { OrderApi } from 'entities/order';
import { StatusTag } from 'entities/status';

import { Button, DialogSimple, Spinner } from 'shared/ui';

type ViewOrderDialogProps = {
  id: string | false;
  onClose: () => void;
};

export const ViewOrderDialog = ({ id, onClose }: ViewOrderDialogProps) => {
  const [isOpenPayment, setIsOpenPayment] = useState(false);
  const [isOpenGoods, setIsOpenGoods] = useState(false);

  const { data } = useQuery({
    queryFn: () => OrderApi.getOrder(id as string),
    queryKey: [OrderApi.KEY, 'getOrders', id],
  });

  if (!data) {
    return <Spinner />;
  }

  const renderData = [
    { title: 'Внутренний ID', value: data.internal_order_id },
    { title: 'Внешний ID', value: data.external_order_id },
    { title: 'Ссылка для коллбека', value: data.callback_url },
    { title: 'Ссылка в случае успеха', value: data.redirect_success_url },
    { title: 'Ссылка в случае ошибки', value: data.redirect_error_url },
    { title: 'Ссылка в случае отмены', value: data.redirect_reject_url },
    { title: 'Общая сумма', value: data.total_sum },
    { title: 'Статус', value: data.status },
    { title: 'Адрес доставки', value: data.delivery_address },
    { title: 'ID кредитного продукта', value: data.credit_product_id },
  ];

  return (
    <DialogSimple isOpen={!!id} onClose={onClose} className="w-full">
      <DialogTitle
        as="h3"
        className="flex items-center gap-3 text-xl font-medium text-white"
      >
        <span> Заказ №{data.internal_order_id}</span>
        <StatusTag status={data.status} />
      </DialogTitle>
      <div className="mt-3 flex flex-col gap-2">
        <div className="grid grid-cols-[66.6666666666666%_1fr] gap-2">
          <div className="mt-3 grid grid-cols-2 gap-3">
            {renderData.map((item) => (
              <Field key={item.title}>
                <Label className="text-sm font-medium text-white">
                  {item.title}
                </Label>
                <Description className="text-sm/6 text-white/50">
                  {item.value}
                </Description>
              </Field>
            ))}
          </div>
          <Steps
            progressDot
            current={1}
            direction="vertical"
            items={data.status_flow.map((item) => ({
              title: item,
              description: item,
            }))}
          />
        </div>
        <div className="flex flex-col items-center gap-2">
          <Button
            className="w-[400px] rounded bg-brand-2 px-2 text-white flex-center hover:bg-brand-2/90"
            onClick={() => setIsOpenGoods(true)}
          >
            Товары по заказу
          </Button>
          <Button
            className="w-[400px] rounded bg-brand-2 px-2 text-white flex-center hover:bg-brand-2/90"
            onClick={() => setIsOpenPayment(true)}
          >
            График платежей
          </Button>
        </div>
      </div>
      <DialogSimple
        isOpen={isOpenGoods}
        onClose={() => setIsOpenGoods(false)}
        className="w-full"
      >
        <DialogTitle as="h3" className="text-xl font-medium text-white">
          Товары по заказу
        </DialogTitle>
        <div className="flex flex-col gap-2">
          {[data.items, data.items_returned].map(
            (item, index) =>
              !!item && (
                <div key={index}>
                  <p className="py-3 text-sm font-medium text-white">
                    {!index ? 'Оформленные' : 'Возврат'}
                  </p>
                  <table className="w-full text-left text-sm text-brand-1">
                    <thead className="sticky top-[20px] bg-brand-1 text-xs uppercase text-white">
                      <tr>
                        {[
                          'Категория',
                          'Наименование',
                          'Артикль',
                          'Цена',
                          'Количество',
                          'Сумма',
                        ].map((item) => (
                          <th key={item} scope="col" className="px-6 py-3">
                            {item}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {data.items.map((item) => {
                        return (
                          <tr
                            key={item.article}
                            className="cursor-pointer border-b border-gray-700 bg-white hover:bg-gray-50"
                          >
                            {Object.values(item).map((item) => (
                              <td className="px-6 py-4 text-center">{item}</td>
                            ))}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ),
          )}
        </div>
      </DialogSimple>
      <DialogSimple
        isOpen={isOpenPayment}
        onClose={() => setIsOpenPayment(false)}
        className="w-full"
      >
        <DialogTitle as="h3" className="text-xl font-medium text-white">
          График платежей
        </DialogTitle>
        <table className="mt-3 w-full text-left text-sm text-brand-1">
          <thead className="sticky top-[20px] bg-brand-1 text-xs uppercase text-white">
            <tr>
              {[
                'Номер платежа',
                'Дата платежа',
                'Сумма платежа',
                'Сумма к оплате',
                '',
              ].map((item) => (
                <th scope="col" className="px-6 py-3">
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.plans
              //@ts-ignore
              .sort((a, b) => +a.stage > +b.stage)
              .map((item) => {
                return (
                  <tr
                    key={item.stage}
                    className="cursor-pointer border-b border-gray-700 bg-white hover:bg-gray-50"
                  >
                    {Object.values(item).map((item) => (
                      <td className="px-6 py-4 text-center">{item}</td>
                    ))}
                    <th scope="col" className="px-6 py-3">
                      <Button
                        className="rounded bg-brand-2 px-2 text-white flex-center hover:bg-brand-2/90"
                        onClick={() => alert('в разработке')}
                      >
                        оплатить
                      </Button>
                    </th>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </DialogSimple>
    </DialogSimple>
  );
};
