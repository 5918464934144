import { cx } from '@emotion/css';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
import { format } from 'date-fns';
import * as yup from 'yup';
import type { InferType } from 'yup';

import { Button, DialogTitle, Field, Input, Label } from '@headlessui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { SelectPartner } from 'features/add-partner/ui';

import { ProductApi } from 'entities/product/Product.api';

import { onAxiosError } from 'shared/lib';
import {
  DialogSimple,
  DialogSimpleProps,
  Button as SharedButton,
  SimpleSelect,
} from 'shared/ui';

type AddCreditProductDialogProps = {
  onClick?: () => void;
} & DialogSimpleProps;

const schema = yup.object({
  name: yup.string().trim().min(4).required(),
  partner_id: yup.number().required(),
  min_limit: yup.number().min(1).required(),
  max_limit: yup.number().min(1).required(),
  period_unit: yup.string().required(),
  period: yup.number().min(0).max(100).required(),
  total_payments: yup.number().min(1).required(),
  initial_payment_percent: yup.number().min(0).max(100).required(),
  active: yup.boolean().required(),
  date_beg: yup.string().required(),
  date_end: yup.string().required(),
});

export const AddProductDialog = ({
  isOpen,
  onClose,
}: AddCreditProductDialogProps) => {
  const client = useQueryClient();

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const [isDaySettled, setIsDaySettled] = useState('DAYS');

  const addProductMutation = useMutation({
    mutationFn: (data: InferType<typeof schema>) => ProductApi.create(data),
    onSuccess: () => {
      client.refetchQueries({ queryKey: [ProductApi.KEY, 'getProducts'] });
      toast.success('Продукт успешно добавлен');
      onClose();
    },
    onError: onAxiosError,
  });

  setValue('period_unit', isDaySettled);
  setValue('active', true);

  const onSubmit = (data: InferType<typeof schema>) => {
    data.date_beg = format(data.date_beg, 'dd.MM.yyyy');
    data.date_end = format(data.date_end, 'dd.MM.yyyy');
    addProductMutation.mutate(data);
  };

  return (
    <DialogSimple isOpen={isOpen} onClose={console.log}>
      <DialogTitle as="h3" className="text-xl font-medium text-white">
        Добавление кредитного продукта
      </DialogTitle>
      <form
        className="grid grid-cols-[1fr_1fr] gap-3"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Field className="mt-3">
          <Label className="text-sm/6 font-medium text-white">Название</Label>
          <Input
            {...register('name')}
            className={cx(
              'block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white',
              'focus:outline-none',
            )}
          />
        </Field>
        <Field className="mt-3">
          <Label className="text-sm/6 font-medium text-white">Партнер</Label>
          <SelectPartner setValue={setValue} valueKey="partner_id" />
        </Field>
        <Field className="mt-3">
          <Label className="text-sm/6 font-medium text-white">Мин. лимит</Label>
          <Input
            {...register('min_limit')}
            type="number"
            min="0"
            defaultValue="1"
            className={cx(
              'block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white',
              'focus:outline-none',
            )}
          />
        </Field>
        <Field className="mt-3">
          <Label className="text-sm/6 font-medium text-white">
            Макс. лимит
          </Label>
          <Input
            {...register('max_limit')}
            type="number"
            min="0"
            defaultValue="1000000"
            className={cx(
              'block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white',
              'focus:outline-none',
            )}
          />
        </Field>
        <Field className="mt-3">
          <Label className="text-sm/6 font-medium text-white">Период</Label>
          <SimpleSelect
            items={[
              { id: 'DAYS', label: 'День' },
              { id: 'MONTHS', label: 'Месяц' },
            ]}
            onChange={(event) => {
              setValue('period_unit', event.id);
              setIsDaySettled(event.id);
            }}
          />
        </Field>
        <Field className="mt-3">
          <Label className="text-sm/6 font-medium text-white">
            Количество периодов
          </Label>
          <Input
            {...register('period')}
            type="number"
            min="0"
            max={isDaySettled ? 100 : 12}
            defaultValue={4}
            className={cx(
              'block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white',
              'focus:outline-none',
            )}
          />
        </Field>
        <Field className="mt-3">
          <Label className="text-sm/6 font-medium text-white">
            Всего платежей
          </Label>
          <Input
            {...register('total_payments')}
            type="number"
            min="0"
            defaultValue={6}
            className={cx(
              'block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white',
              'focus:outline-none',
            )}
          />
        </Field>
        <Field className="mt-3">
          <Label className="text-sm/6 font-medium text-white">
            Первоначальный взнос (%)
          </Label>
          <Input
            {...register('initial_payment_percent')}
            type="number"
            min="0"
            step="0.01"
            defaultValue={25.12}
            className={cx(
              'block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white',
              'focus:outline-none',
            )}
          />
        </Field>
        <Field className="mt-3">
          <Label className="text-sm/6 font-medium text-white">
            Дата начала
          </Label>
          <Input
            {...register('date_beg')}
            type="date"
            className={cx(
              'block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white',
              'focus:outline-none',
            )}
          />
        </Field>
        <Field className="mt-3">
          <Label className="text-sm/6 font-medium text-white">
            Дата окончания
          </Label>
          <Input
            {...register('date_end')}
            type="date"
            className={cx(
              'block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white',
              'focus:outline-none',
            )}
          />
        </Field>
        <div className="mt-4 flex gap-2">
          <Button
            className="inline-flex items-center gap-2 rounded-md bg-brand-1 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-200 data-[hover]:text-gray-700"
            onClick={onClose}
          >
            Отмена
          </Button>
          <SharedButton
            className="inline-flex items-center gap-2 rounded-md bg-brand-2 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-brand-2/60 data-[open]:bg-brand-2/70 data-[focus]:outline-1 data-[focus]:outline-white"
            disabled={addProductMutation.isPending}
          >
            Добавить
          </SharedButton>
        </div>
      </form>
      <div className="mt-3 flex flex-col gap-1">
        {errors &&
          Object.values(errors).map(({ message }) => (
            <span key={message} className="text-sm/6 font-medium text-rose-400">
              {message}
            </span>
          ))}
      </div>
    </DialogSimple>
  );
};
