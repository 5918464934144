import './styles/main.scss';

import {
  AuthPage,
  CreditProductPage,
  MerchantPage,
  PartnersPage,
  SettingsPage,
} from 'pages';
import { Layout } from 'widgets';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { OrdersPage } from 'pages/orders-page';

import { XSessionIdKey } from 'shared/contants';
import { AuthContext } from 'shared/hooks';
import { RoutesPath } from 'shared/routes-path';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const App = () => {
  const [isAuth, setIsAuth] = useState(!!sessionStorage.getItem(XSessionIdKey));

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthContext.Provider
          value={{
            isAuth,
            setIsAuth,
          }}
        >
          <Pages />
        </AuthContext.Provider>
      </BrowserRouter>
      <ToastContainer closeOnClick theme="colored" />
    </QueryClientProvider>
  );
};

const Pages = () => {
  return (
    <Routes>
      <Route path={RoutesPath.AUTH} element={<AuthPage />} />
      <Route element={<Layout />}>
        <Route path={RoutesPath.SETTINGS} element={<SettingsPage />} />
        <Route path={RoutesPath.MERCHANTS} element={<MerchantPage />} />
        <Route path={RoutesPath.PARTNERS} element={<PartnersPage />} />
        <Route path={RoutesPath.ORDERS} element={<OrdersPage />} />
        <Route path={RoutesPath.PRODUCTS} element={<CreditProductPage />} />
      </Route>
      <Route
        path="*"
        element={<Navigate replace to={RoutesPath.MERCHANTS} />}
      />
    </Routes>
  );
};
