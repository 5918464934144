import { Fragment, useState } from 'react';

import { ViewOrderDialog } from 'features/view-order/ui';

import { OrderApi } from 'entities/order/Order.api';

import type { AccessorKey } from 'shared/typings';
import { Table } from 'shared/ui';

type OrderBase = {
  date: string;
  guid: string;
  number: string;
  clientFio: string;
  status: string;
  order_flow: string;
  sum: string;
};

export const OrdersTable = () => {
  const [isOpenId, setIsOpenId] = useState<string | false>(false);

  const columns: AccessorKey<OrderBase>[] = [
    { accessorKey: 'guid' },
    { accessorKey: 'date' },
    { accessorKey: 'number' },
    { accessorKey: 'clientFio' },
    { accessorKey: 'status' },
    { accessorKey: 'order_flow' },
    { accessorKey: 'sum' },
  ];

  const thead = (
    <tr>
      <th
        scope="col"
        className="w-[50px] whitespace-nowrap  px-6 py-3 text-center"
      >
        дата
      </th>
      <th scope="col" className="whitespace-nowrap px-6  py-3 ">
        номер
      </th>
      <th scope="col" className="whitespace-nowrap  px-6 py-3">
        ФИО клиента
      </th>
      <th scope="col" className="whitespace-nowrap  px-6 py-3">
        статус
      </th>
      <th scope="col" className="whitespace-nowrap  px-6 py-3">
        тип магазина
      </th>
      <th scope="col" className="whitespace-nowrap  px-6 py-3">
        сумма (валюта)
      </th>
    </tr>
  );

  const renderRow = (order: OrderBase) => {
    return (
      <tr
        key={order.guid}
        className="h-12 cursor-pointer border-gray-700 bg-white hover:bg-brand-1/20 [&:not(:last-child)]:border-b"
        onClick={() => setIsOpenId(order.guid)}
        title="Просмотр"
      >
        <td className="px-6 py-4">{order.date}</td>
        <td className="px-6 py-4">{order.number}</td>
        <td className="px-6 py-4">{order.clientFio}</td>
        <td className="px-6 py-4">{order.status}</td>
        <td className="px-6 py-4">{order.order_flow}</td>
        <td className="px-6 py-4">{order.sum}</td>
      </tr>
    );
  };

  return (
    <Fragment>
      <Table
        queryKeys={[OrderApi.KEY, 'getOrders']}
        queryFn={OrderApi.getOrders}
        columns={columns}
        thead={thead}
        renderRow={renderRow}
      />
      {isOpenId && (
        <ViewOrderDialog id={isOpenId} onClose={() => setIsOpenId(false)} />
      )}
    </Fragment>
  );
};
